export const MUT_UPDATE_LOCAL_STORAGE = "updateLocalStorage";
export const MUT_UPDATE_ACCESS = "updateAccess";
export const MUT_DESTROY_TOKEN = "destroyToken";
export const MUT_UPDATE_PROFILE = "updateProfile";
export const MUT_UPDATE_SIDEBAR_SELECTED = "updateSidebarSelected";
export const MUT_DRAWER_EXPAND = "mutDrawerExpand";
export const MUT_NOTIFICATION = "mutNotification";
export const MUT_SHOW_NOTIFICATION = "mutShowNotification";
export const MUT_DEVICE_READY = "mutDeviceReady";
export const MUT_DATA_MENU = "mutDataCRMMenu";
export const MUT_DATA_COLORS = "mutDataCRMColors";
export const MUT_DATA_NOMPRICE = "mutDataCRMNomPrice";
export const MUT_DATA_NOMENCLATORS = "mutDataCRMNomenclators";
export const MUT_CONFIG = "mutDataCRM";
export const MUT_CONFIG_ID = "mutConfigID";
export const MUT_USEDOCUSIGN = "mutUseDocuSign";
export const MUT_METHODCALL = "mutMethodCall";
export const MUT_REQUIRENOTES = "mutRequireNotes";
export const MUT_SELLERN = "mutSellerN";
export const MUT_PATIENTN = "mutPatientN";
export const MUT_COORDINATORN = "mutCoordinatorN";
export const MUT_TYPEAPPOINT = "mutTypesAppoint";
export const MUT_VIEWPAYMENTS = "mutviewpayments";
export const MUT_VIEWPPS = "mutviewPPS";
export const MUT_VIEWLABS = "mutviewLabs";
export const MUT_VIEWMHISTORY = "mutviewMHistory";
export const MUT_SOUND = "mutSound";
export const MUT_APPROVEQ = "mutapproveQ";
export const MUT_SENDQ = "mutsendQ";
export const MUT_VIEWPATIENTSTATUS = "mutviewpatientStatus";
export const MUT_USEMERCHANT = "mutUseMerchant";
export const MUT_TAB_DETAILS = "muttabPatientDetails";
export const MUT_SELECTDOCUMENT = "mutselecDocuments";
export const MUT_RANGES = "mutReqRange";
export const MUT_TOTAL_ITEMS = "mutTotalItems";
