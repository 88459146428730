/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "@/models/Link";
import {
  rolColor as getRolColor,
  rolIcon as getRolIcon,
  statusColor as getStatusColor,
} from "@/utils/rolesStatus";

export function loggedIn(state: any): boolean {
  return state.accessToken != null || state.accessToken != undefined;
}

export function someSelected(state: any): boolean {
  return state.sidebarSelected != null;
}

export function sidebarSelected(state: any): void {
  state.sidebarSelected;
}

/**
 * Get the global status color
 * @param {"Idle" | "Offline" | "Busy" | "Reserved"} state
 */
export function statusColor(state: any): string {
  return getStatusColor(state.profile.agentStatus);
}
export function status(state: any): string {
  return state.status;
}

export function rolIcon(state: any): string {
  return getRolIcon(state.profile.rol);
}

export function rolColor(state: any): string {
  return getRolColor(state.profile.rol);
}

// Roles test
export function isSuper(state: any): boolean {
  return state.profile.rol == "SUPER";
}
export function getMethodCalls(state: any) {
  return state.dataCRM.methodCall;
}
export function nomAccesory(state: any): string {
  return state.dataCRM.nomenclators.accessories;
}
export function getSellerN(state: any): string {
  return state.dataCRM.sellerN;
}
export function getpatientN(state: any): string {
  return state.dataCRM.patientN;
}
export function getCoordinatorN(state: any): string {
  return state.dataCRM.coordinatorN;
}
export function getappointmetsTypes(state: any): any {
  return state.dataCRM.appointmetsTypes;
}
export function getviewpayments(state: any): any {
  return state.dataCRM.viewPayments;
}
export function getviewPps(state: any): any {
  return state.dataCRM.viewPPS;
}
export function getUseMerchant(state: any): any {
  return state.dataCRM.useMerchant;
}
export function getviewLabs(state: any): any {
  return state.dataCRM.viewLabs;
}
export function getviewMHistory(state: any): any {
  return state.dataCRM.viewMHistory;
}
export function getsoundNotify(state: any): any {
  return state.dataCRM.soundNotification;
}
export function getapproveQ(state: any): any {
  return state.dataCRM.approveQ;
}
export function getsendQ(state: any): any {
  return state.dataCRM.sendQ;
}
export function getviewpatientStatus(state: any): any {
  return state.dataCRM.viewpatientStatus;
}
export function gettabPatientDetails(state: any): any {
  return state.dataCRM.tabPatientDetails;
}
export function getselecDocuments(state: any): any {
  return state.dataCRM.selecDocuments;
}

export function isAdmin(state: any): boolean {
  return state.profile.rol == "ADMIN";
}
export function dataMenu(state: any): Link[] {
  return state.dataCRM.menu as Link[];
}

export function isWorker(state: any): boolean {
  return state.profile.rol == "WORKER";
}

export function isDoctor(state: any): boolean {
  return state.profile.rol == "DOCTOR";
}

export function isSupervisor(state: any): boolean {
  return state.profile.rol == "SUPERVISOR";
}

export function accesToken(state: any) {
  return state.access_token;
}
export function refreshToken(state: any) {
  return state.refresh_token;
}

export function getShowPrice(state: any) {
  return state.dataCRM.nomenclatorsPrice;
}
export function getPhone(state: any) {
  return state.profile.phone;
}
export function getUser(state: any) {
  return state.profile.username;
}
export function getName(state: any) {
  return state.profile.fullname;
}
export function getRequireNote(state: any) {
  return state.dataCRM.requireNotes;
}
